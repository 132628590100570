import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';
import './CreateBlog.css';
import LZString from 'lz-string';
import Modal from 'react-modal';
import showToast from '../Toast/Toast';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { BaseUrl } from '../config/Config';
import { Link } from 'react-router-dom';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const CreateBlog = () => {
  const { id } = useParams();
  const { state } = useLocation()
  // console.log("POst", state)
  const [blogData, setBlogData] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    subcategory: '',
    created_by: '',
    thumbnail: '',
  });

  // const categories = ['Technology', 'Health', 'Travel', 'Education', 'Business'];


  useEffect(() => {
    if (id) {
      fetchBlogDetails(id);
    }
    if (state?.blog_id) {
      fetchBlogDetails(state.blog_id)
    }
  }, [id]);


  // const fetchBlogDetails = async () => {
  //   try {
  //     const response = await axios.get(`https://t6v9bwcc-7007.inc1.devtunnels.ms/api/getAdminBlogById?id=${id}`);
  //     const blog = response.data.message;
  //     console.log("Fetched Blog Data:", blog);
  //     // if (blog) {
  //     //   setBlogData(blog);
  //     // }
  //     if (blog) {
  //       setBlogData(blog); 


  //       setFormData({
  //         title: blog.blog_title || '',
  //         description: blog.blog_description || '',
  //         category: blog.categories || '', 
  //         created_by: blog.created_by || '',
  //         thumbnail: blog.thumbnail || '',
  //       });
  //     }
  //     console.log("BlogData...",blogData)
  //     console.log("formdata",formData)
  //   } catch (error) {
  //     console.error("Error fetching blog details:", error);
  //   }
  // };

  const fetchBlogDetails = async (blog_id) => {
    try {
      const response = await axios.get(
        `${BaseUrl}/getAdminBlogById?id=${blog_id}`
      );

      const blogArray = response.data.message;
      // console.log("Fetched Blog Data:", blogArray);

      if (Array.isArray(blogArray) && blogArray.length > 0) {
        const blog = blogArray[0];
        // console.log("Extracted Blog:", blog);

        setBlogData(blog);


        setFormData({
          title: blog.blog_title || '',
          description: blog.blog_description || '',
          category: blog.category || '',
          subcategory: blog.subcategory || '',
          created_by: blog.created_by || '',
          thumbnail: blog.thumbnail || '',
          id: blog.blog_id || ''
        });
      } else {
        console.error("No blog data found.");
      }
    } catch (error) {
      console.error("Error fetching blog details:", error);
    }
  };

  // useEffect(() => {
  //   if (blogData) {
  //     setFormData({
  //       title: blogData.blog_title || '',
  //       description: blogData.blog_description || '',
  //       category: blogData.categories || '',
  //       created_by: blogData.created_by || '',
  //       thumbnail: blogData.thumbnail || '',
  //     });
  //   }
  // }, [blogData]); 
  const [Categories, setCategories] = useState()

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    if (name === 'thumbnail' && files.length > 0) {
      const file = files[0];
      const base64 = await ConvertFileToBase64(file);
      setFormData((prev) => ({ ...prev, [name]: base64 }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const ConvertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        const compressedBase64 = LZString.compressToBase64(base64);
        resolve(compressedBase64);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  // Function to decompress the Base64 string
  // const DecompressBase64 = (compressedBase64) => {
  //   return LZString.decompressFromBase64(compressedBase64);
  // };


  const [isModalOpen, setisOpenModal] = useState(false)
  const handleContentChange = (value) => {
    setFormData((prev) => ({ ...prev, description: value }));
  };

  const handlePublish = async () => {
    try {
      const response = await axios.post(`${BaseUrl}/publishBlog`, formData);
      // console.log('Blog Published:', response.data);
      if(response.data.status===true){
        toast.success("Blog Created Successfully")
        setFormData({
          title: '',
          description: '',
          category: '',
          subcategory: '',
          created_by: '',
          thumbnail: '',
        })
      }
     
    } catch (error) {
      console.error('Error while publishing the blog:', error);
      toast.error(error);
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.post(`${BaseUrl}/postBlogs`, formData);
      // console.log('Blog Published:', response.data);
      if (response.data.status === true) {
        toast.success("Blog Created Successfully")
        setFormData({
          title: '',
          description: '',
          category: '',
          subcategory: '',
          created_by: '',
          thumbnail: '',
        })
      }

    } catch (error) {
      console.error('Error while publishing the blog:', error);
      toast.error(error);
    }
  }
  const handleEdit = async (id) => {
    
    try {
      const response = await axios.put(
        `${BaseUrl}/editBlog?id=${id}`,
        formData
      );
      // console.log('Blog Updated:', response.data);
      if (response.data.status === true) {
        toast.success("Blog Updated successfully");
        setFormData({
          title: '',
          description: '',
          category: '',
          subcategory: '',
          created_by: '',
          thumbnail: '',
        })
        fetchBlogDetails()
      }

    } catch (error) {
      console.error('Error while updating the blog:', error);
      toast.error(error);
    }
  };
  const [subCategory, setSubCategory] = useState()
  useEffect(() => {
    if (formData.category && Categories?.length > 0) {
      //  console.log("ff",Categories.filter((item,i)=>item?.parent===formData.category))
      setSubCategory(Categories.filter((item, i) => item?.parent === formData.category)[0]?.child)
    }
  }, [formData])
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/getBlogsCategories`)
      let temp = null
      if (response.status) {
        temp = response.data.message
        setCategories(temp)
      }
      // console.log(Categories)
    } catch (err) {
      console.log(err)
    }
  }
  ///console.log(payloadData)
  useEffect(() => {
    fetchData()
  }, [])
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image'
  ];
  const createMarkup = (html) => {
    return { __html: html };
  };
  return (
    <div className="blog-form-container">
      <Link to='/blogdashboard'>
        <button className='back-dashboard'>Back</button></Link>
      <h3>{id ? 'Edit Your Blog' : 'Publish Your Blog'}</h3>

      <div className="form-group">
        <label htmlFor="title">Title:</label>
        <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} placeholder="Enter blog title" />
      </div>

      {/* <div className="form-group">
        <label htmlFor="description">Content:</label>
        <ReactQuill id="description" theme="snow" value={formData.description} onChange={handleContentChange} placeholder="Write your content here..." />
      </div> */}
      <div className="form-group">
        <label htmlFor="description">Content:</label>
        <ReactQuill
          id="description"
          theme="snow"
          value={formData.description}
          onChange={handleContentChange}
          placeholder="Write your content here..."
          modules={modules}
          formats={formats}
          className="custom-quill"
        />
      </div>

      <div className="form-group">
        <label htmlFor="category">Category:</label>
        {/* <select id="category" name="category" value={formData.category} onChange={handleChange}>
          <option value="" disabled>Select a category</option>
          {categories.map((cat, index) => (
            <option key={index} value={cat.categories}>{cat}</option>
          ))}
        </select> */}
        <select id="category" name="category" value={formData.category} onChange={handleChange}>
          <option value="" disabled>Select a category</option>
          {Categories && Categories.map((cat, index) => (
            <option key={index} value={cat.parent}>{cat?.parent}</option>
          ))}
        </select>
      </div>

      {formData.category && <div className='form-group'>
        <label htmlFor="subcategory">SubCategory:</label>
        <select id="subcategory" name="subcategory" value={formData.subcategory} onChange={handleChange}>
          {
            subCategory && [{ category_name: "Select an Child Category" }, ...subCategory].map((item, i) => {
              return (
                <option key={i} value={item.category_name}>{item?.category_name}</option>
              )
            })
          }
        </select>
      </div>}
      <div className="form-group">
        <label htmlFor="created_by">Author:</label>
        <input type="text" id="created_by" name="created_by" value={formData.created_by} onChange={handleChange} placeholder="Enter your name" />
      </div>

      <div className="form-group">
        <label htmlFor="thumbnail">Thumbnail:</label>
        <input type="file" id="thumbnail" name="thumbnail" accept="image/*" onChange={handleChange} />
        {formData.thumbnail && <img src={LZString.decompressFromBase64(formData.thumbnail)} alt="Thumbnail Preview" className="thumbnail-preview" />}
      </div>

      <div className="button-group">
        {!id ? (
          <>
            <button onClick={handlePublish} className="form-button publish-button">Publish</button>
            <button onClick={handleSave} className="form-button save-button">Archive</button>
            <button onClick={() => {
              setisOpenModal(true)
            }} className='edit-with-preview-btn'>Preview</button>
          </>
        ) : (
          <>
            <button onClick={() => handleEdit(id)} className="form-button edit-button">Update</button>
            <button onClick={() => {
              setisOpenModal(true)
            }} className='edit-with-preview-btn'>Edit With Preview</button>
          </>
        )}

      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setisOpenModal(false)}
        className={{
          base: "custom-modal-base",
          afterOpen: "custom-modal-base_after-open",
          beforeClose: "custom-modal-base_before-close",
        }}
        overlayClassName={{
          base: "custom-overlay-base",
          afterOpen: "custom-overlay-base_after-open",
          beforeClose: "custom-overlay-base_before-close",
        }}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={300}
      >
        <div className="custom-modal-header">
          <h2 className="custom-modal-title">Edit With Preview</h2>
          <button className="custom-modal-close" onClick={() => setisOpenModal(false)}>
            ✖
          </button>
        </div>

        <div className='custom-modal-body'>
          <ReactQuill
            id="description"
            theme="snow"
            value={formData.description}
            onChange={handleContentChange}
            placeholder="Write your content here..."
            modules={modules}
            formats={formats}
            // style={{width:'40%',height:'100%',maxHeight:'100%',minHeight:'100%'}}
            className="custom-quill-modal"
          />
          <div className='vertical-line'></div>
          <div className='preview-content'>
            <p
              className="blog-description"
              dangerouslySetInnerHTML={createMarkup(
                formData.description)}></p>

          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default CreateBlog;
