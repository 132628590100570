import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Pagination from '../Pagination';
import PostStyle2 from '../Post/PostStyle2';
import Div from '../Div';
import Sidebar from '../Sidebar';
import Spacing from '../Spacing';
import axios from 'axios';
import {Icon} from '@iconify/react'
import { Link } from 'react-router-dom';
import LZString from 'lz-string';
import {BaseUrl} from '../config/Config'

import Button from '../Button'
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      // timeZoneName: 'short',
  });
};



export default function BlogPage() {
  const [blogData, setBlogData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  pageTitle('Blog');
  const [count,setCount] = useState(0)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getCount = async () =>{
    try{
      const data = await axios.get(`${BaseUrl}/getCount`)
      const result = data.data.message[0];
      // console.log("result",result)
      setCount(result.total_blogs)
    }catch(err){
      console.log(err)
    }
  }
  const [offset,setoffset] = useState(0)
  const blogdata = async () => {
    try {
      const data = await axios.get(`${BaseUrl}/getBlogForUser?offSet=${offset}`)
      const result = data.data.message;
      setBlogData(result)
      console.log(result);
    } catch (err) {
      console.log("error", err)
    }

  }

  useEffect(() => {
    blogdata()
    getCount()
  }, [offset])

  const totalPages = Math.ceil(count/3);

  // const no_of_blogs = 3;
  // const total_blogs = blogData.length;
  // console.log("total blogs", offset/totalPages);
  // const no_of_pages = Math.ceil(total_blogs / no_of_blogs);
  // console.log("no ofpages", no_of_pages);
  // const start = currentPage * no_of_blogs;
  // console.log("start", start);
  // const end = start + no_of_blogs;
  // console.log("end", end)
 const handleChangePage= (n)=>{
  setCurrentPage(n)
  window.scrollTo({ top: 0, behavior: 'smooth' });
  
 }
 const [currentpage,setcurrentpage] = useState(1)
  const goToPreviousPage = ()=>{
    // setCurrentPage((prev)=>prev-1)
    setcurrentpage(currentpage-1)
    setoffset(offset-3)
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
  }
  const goToNextPage=()=>{
    // setCurrentPage((prev)=>prev+1)
    setcurrentpage(currentpage+1)
    setoffset(offset+3)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      {/* <PageHeading
        title="Our Blog"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText="Blog"
      /> */}
      <Spacing lg="80" md="80" />
      <Div className="container d-flex justify-content-center" >
        <Div className="row justify-content-center">
          <Div className="col-lg-8 m-auto d-flex flex-column align-items-center" >
            {blogData.length>0 && 
            [...blogData].map((item, index) => (
              <Div key={item.blog_id}>
                <PostStyle2
                  thumb={LZString.decompressFromBase64(item.thumbnail)}
                  // title={item.blog_title}
                  title={
                    <Link 
                      to={`/blogdetails?id=${item.blog_id}&title=${item.blog_title.replace(/\s+/g, "_")}`} 
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {item.blog_title}
                    </Link>
                  }
                  subtitle={item.blog_description?item.blog_description.trim().split(" ").slice(0, 20).join(" ") + " ...":"...."}
                  date={formatDate(item.created_at)}
                  category={item.category}

                //   categoryHref={item.categoryHref}
                //   href={item.href}
                />
                 <Link to=
                 {`/blogdetails?id=${item.blog_id}&title=${item.blog_title.replace(" ","_")}`}
                //  {`/blogdetails/${item.blog_id}&${item.blog_title.replace(/\s+/g, "-")}`}
                 ><button style={{backgroundColor:"transparent",outline:"none"}}>see more</button></Link>
                 <Spacing lg="95" md="60" />
               
              </Div>
            ))}
           
            <Spacing lg="60" md="40" />
            
            {/* <div>
              <h1>{no_of_pages}</h1>
              <button disabled={currentPage===0} onClick={goToPreviousPage}>prev</button>
              {[...Array(no_of_pages).keys()].map((n) => (
                <button style={{ color: "black" }} key={n} onClick={()=>handleChangePage(n)}>{n}</button>
              ))}
              <button disabled={currentPage===no_of_pages-1} onClick={goToNextPage}>next</button>
            </div> */}
            <div className="cs-pagination_box cs-center cs-white_color cs-mp0 cs-semi_bold">
              <button
                className={`cs-pagination_item cs-center ${
                  offset === 0 ? "disabled" : ""
                }`}
                onClick={goToPreviousPage}
                disabled={offset === 0}
              >
                <Icon icon="akar-icons:chevron-left" size={23}/>
              </button>

              {/* {[...Array(no_of_pages).keys()].map((n) => (
                <button
                  key={n}
                  className={`cs-pagination_item cs-center ${
                    currentPage === n ? "active" : ""
                  }`}
                  onClick={() => handleChangePage(n)}
                >
                  {n + 1}
                </button>
              ))} */}
              {new Array({length:count}).map((item,i)=>{
                  return(
                    <button
                    key={i}
                    className={`cs-pagination_item cs-center $
                      {
                      //  === n ? "active" : ""
                    }`
                  }
                    // onClick={() => handleChangePage(n)}
                  >
                    {currentpage}
                  </button>
                  )
              })
              }
              <button
                className={`cs-pagination_item cs-center ${
                  offset/totalPages === totalPages-1 ? "disabled" : ""
                }`}
                onClick={goToNextPage}
                disabled={offset/totalPages === totalPages-1}
              >
                <Icon icon="akar-icons:chevron-right" size={23} />
              </button>
            </div>
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg="0" md="80" />
            {/* <Sidebar /> */}
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />

    </>
  );
}