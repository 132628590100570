import React, { useState } from "react";
import "./BlogDashboard.css"; 
import axios from "axios";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { BaseUrl } from "../config/Config";
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      // timeZoneName: 'short',
  });
};
const BlogDashboard = () => {
  const[blogData,setBlogData]=useState([])
 const user = localStorage.getItem('user')
 
  const [profile,setprofile] = useState()
 useEffect(()=>{
  setprofile(JSON.parse(user))
 },[user])
 const getdata = async()=>{
  const data = await axios.get(`${BaseUrl}/getBlogs`)
  const result = data.data.message
  setBlogData(result)
  

 }


const handleStateChange = async (e,id,state) =>{
  e.preventDefault()
  try{
    const response = await axios({
      method:'PUT',
      url:`${BaseUrl}/changeState`,
      data:{
        id:id,
        state:state
      }
    })
  }catch(err){  
    console.log(err)
  }
}
const handleDelete = async (id) => {
  if (window.confirm("Are you sure you want to delete this blog?")) {
    try {
      await axios.delete(`${BaseUrl}/deleteBlog?id=${id}`);
      setBlogData(blogData.filter((post) => post.blog_id !== id)); 
      toast.error("Blog Deleted Successfully")
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error(error);
    }
  }
};
const navigation = useNavigate()
const [type,settype] = useState("Select an Type")
useEffect(()=>{
  getdata()
   },[handleStateChange,handleDelete])
  //  console.log("blog data",blogData)

  return (
    <div className="table-container">
        {/* <div style={{color:'black', margin:'2%',display:"flex",justifyContent:"space-between"}} className="create-new-container">
          <div style={{display:"flex",gap:'10px'}}>
          <Link to='/createblog'>
            <button className="create-new-btn">Create New</button>
            </Link>
            <Link to='/createcategory'>
            <button className="create-category">Create Category</button>
            </Link>
            <select className='selectType' onChange={(e)=>{
              settype(e.target.value)
              // console.log(e.target.value)
            }}>
                <option value={null}>Select an Type</option>
                <option value={"all"}>All</option>
                <option value={0}>Archived</option>
                <option value={1}>Published</option>
              </select>
              </div>
              <div>
                <p style={{color:'white'}}>Hi,{'\t'}{profile?.fullname}</p>
              </div>
              <div>
              <button onClick={()=>{
                localStorage.removeItem("user");
                window.location.href = '/login'
              }} className="logout-btn">Log Out</button>
              </div>
        </div> */}
        <div className="create-new-container">
  <div className="left-section">
    <Link to='/createblog'>
      <button className="create-new-btn">Create New</button>
    </Link>
    <Link to='/createcategory'>
      <button className="create-category">Create Category</button>
    </Link>
    <select className='selectType' onChange={(e) => settype(e.target.value)}>
      <option value={null}>Select a Type</option>
      <option value={"all"}>All</option>
      <option value={0}>Archived</option>
      <option value={1}>Published</option>
    </select>
  </div>

  <div className="profile-section">
    <p>Hi, {profile?.fullname}</p>
  </div>

  <div className="right-section">
    <button onClick={() => {
      localStorage.removeItem("user");
      window.location.href = '/login';
    }} className="logout-btn">Log Out</button>
  </div>
</div>
      <table className="blog-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Author</th>
            <th>Category</th>
            <th>Posted Date</th>
          </tr>
        </thead>
        <tbody>
          {blogData.length>0 && blogData.map((post) => {
            if(type!=='Select an Type' && type!=="all"){
               if(post.is_published===parseInt(`${type}`)){
                return  (
                  <tr key={post.blog_id}>
                    <td>
                      <Link to={`/blogdetails?id=${post.blog_id}&title=${post.blog_title.replace(" ","_")}`}>
       
                      <span className="title-link">{post.blog_title}</span></Link>
                      <div className="actions">
                        <a href={`/editblog/${post.blog_id}`} className="action-link">Edit</a> | 
                        <a href="#" className="action-link delete" onClick={()=>handleDelete(post.blog_id)}>Delete</a> | 
                        <a href={`/blogdetails?id=${post.blog_id}&title=${post.blog_title.replace(" ","_")}`} className="action-link">View</a> | 
                        <a href="#" onClick={(e)=>{
                          e.preventDefault()
                          navigation('/createBlog',{
                            state:post
                          })
                        }} className="action-link">Clone</a> | 
                        <a href="#" onClick={(e)=>handleStateChange(e,post.blog_id,0)} style={post.is_published===1?{}:{pointerEvents:'none',color:'gray'}} className="action-link">Archive</a> | 
                        <a href="#" onClick={(e)=>handleStateChange(e,post.blog_id,1)} style={post.is_published===0?{}:{pointerEvents:'none',color:'gray'}} className="action-link">Publish</a> | 
                      </div>
                    </td>
                    <td>{post.created_by}</td>
                    <td>{post.category}</td>
                    <td>{formatDate(post.created_at)}</td>
                  </tr>
                )
               }
            }else{
              return  (
                <tr key={post.blog_id}>
                  <td>
                    <Link to={`/blogdetails?id=${post.blog_id}&title=${post.blog_title.replace(" ","_")}`}>
     
                    <span className="title-link">{post.blog_title}</span></Link>
                    <div className="actions">
                      <a href={`/editblog/${post.blog_id}`} className="action-link">Edit</a> | 
                      <a href="#" className="action-link delete" onClick={()=>handleDelete(post.blog_id)}>Delete</a> | 
                      <a href={`/blogdetails?id=${post.blog_id}&title=${post.blog_title.replace(" ","_")}`} className="action-link">View</a> | 
                      <a href="#" onClick={(e)=>{
                        e.preventDefault()
                        navigation('/createBlog',{
                          state:post
                        })
                      }} className="action-link">Clone</a> | 
                      <a href="#" onClick={(e)=>handleStateChange(e,post.blog_id,0)} style={post.is_published===1?{}:{pointerEvents:'none',color:'gray'}} className="action-link">Archive</a> | 
                      <a href="#" onClick={(e)=>handleStateChange(e,post.blog_id,1)} style={post.is_published===0?{}:{pointerEvents:'none',color:'gray'}} className="action-link">Publish</a> | 
                    </div>
                  </td>
                  <td>{post.created_by}</td>
                  <td>{post.category}</td>
                  <td>{formatDate(post.created_at)}</td>
                </tr>
              )
            }
          }
          )}
        </tbody>
      </table>
      <ToastContainer/>
    </div>
  );
};

export default BlogDashboard;