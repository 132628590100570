// import axios from 'axios'
// import React, { useEffect, useState } from 'react'
// import './CreateCategory.css'

// function CreateCategory() {
//   const [Categories, setCategories] = useState()
//   const [payloadData, setPayloadData] = useState({
//     parentCategory:'',
//     childCategory:''
//   })

//   const handleSubmit = async (e) =>{
//     e.preventDefault()
//     try{
//       if(payloadData.parentCategory && payloadData.childCategory){
//         const data = {
//           parent:payloadData.parentCategory,
//           child:payloadData.childCategory
//         }
//         console.log(data)
//         const response = await axios({
//           method:'POST',
//           url:'https://3j6lq1k0-7007.inc1.devtunnels.ms/api/postCategories',
//           data:data
//         })
//         console.log(response)
//       }
//     }catch(err){
//       console.log(err)
//     }
//   }
//   const fetchData = async () =>{
//       try{
//        const response = await axios.get('https://3j6lq1k0-7007.inc1.devtunnels.ms/api/getBlogsCategories')
//        let temp = null
//        if(response.status){
//           temp = response.data.message
//           setCategories(temp)
//        }
//        console.log(Categories)
//       }catch(err){
//         console.log(err)
//       }
//   } 
//   ///console.log(payloadData)
//   useEffect(()=>{
//     fetchData()
//   },[])
//   return (
//     <div>
//       <div>
//         <input type='text' style={{color:'black'}} value={payloadData.parentCategory} onChange={(e)=>setPayloadData({...payloadData,parentCategory:e.target.value})} placeholder='Enter the Parent Category'/>
//         <input type='text' style={{color:'black'}}value={payloadData.childCategory} onChange={(e)=>setPayloadData({...payloadData,childCategory:e.target.value})} placeholder='Enter the Child Category'/>
//       </div>
//       <div className='tree'>
//       {Categories && Categories.map((item,i)=>{
//         return(
//           <>
//           <label for='parent'>{item.parent}</label>
//           <input type='checkbox' id='parent' value={item.parent} checked={item?.parent===payloadData.parentCategory}  onChange={(e)=>setPayloadData({...payloadData,parentCategory:e.target.value})} placeholder='Enter the Parent Category'></input>
//           <div className='branch'>
//           {
//             [...item?.child].length>0?
//             [...item?.child].map((ele,i)=>{
//               return(
//                 <>
//                 <label for='child'>{ele.category_name}</label>
//                 <input type='checkbox' id="child" value={ele.category_name} checked={ele.category_name===payloadData.childCategory} onChange={(e)=>setPayloadData({...payloadData,childCategory:e.target.value})} placeholder='Enter the Child Category'></input>
//                 </>
//               )
//             })
//             :<></>
//           }</div>
//           </>
//         )
//       })
//       }</div>
//       <button onClick={(e)=>handleSubmit(e)}>Submit</button>
//     </div>
//   )
// }

// export default CreateCategory














































import axios from "axios";
import React, { useEffect, useState } from "react";
import "./CreateCategory.css";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BaseUrl } from "../components/config/Config";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function CreateCategory() {
  const [Categories, setCategories] = useState([]);
  const [payloadData, setPayloadData] = useState({
    parentCategory: "",
    childCategory: "",
  });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (payloadData.parentCategory && payloadData.childCategory) {
  //       const data = {
  //         parent: payloadData.parentCategory,
  //         child: payloadData.childCategory,
  //       };
  //       console.log(data);
  //       const response = await axios.post(
  //         `${BaseUrl}/postCategories`,
  //         data
  //       );
  //       console.log(response);
       
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (payloadData.parentCategory && payloadData.childCategory) {
        const data = {
          parent: payloadData.parentCategory,
          child: payloadData.childCategory,
        };
        console.log(data);
        const response = await axios.post(
          `${BaseUrl}/postCategories`,
          data
        );
        
        if (response.status === 200) {
          toast.success("Category created successfully!");
          setPayloadData({ parentCategory: "", childCategory: "" });
          fetchData();
        }
      } else {
        toast.error("Please fill in both fields.");
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to create category. Try again!");
    }
  };
  

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/getBlogsCategories`
      );
      if (response.status === 200) {
        setCategories(response.data.message || []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="category-container">
      <Link to='/blogdashboard'>
      <button className="back-dashboard">Back</button>
      </Link>
      <div className="input-container">
        <input
          type="text"
          value={payloadData.parentCategory}
          onChange={(e) =>
            setPayloadData({ ...payloadData, parentCategory: e.target.value })
          }
          placeholder="Enter the Parent Category"
        />
        <input
          type="text"
          value={payloadData.childCategory}
          onChange={(e) =>
            setPayloadData({ ...payloadData, childCategory: e.target.value })
          }
          placeholder="Enter the Child Category"
        />
      </div>

      <div className="tree">
  {Categories.length > 0 &&
    Categories.map((item, i) => (
      <div key={i} className="tree-node parent">
        <input
          type="checkbox"
          id={`parent-${i}`}
          value={item.parent}
          checked={item.parent === payloadData.parentCategory}
          onChange={(e) =>
            setPayloadData({
              ...payloadData,
              parentCategory: e.target.value,
            })
          }
        />
        <label htmlFor={`parent-${i}`} className="tree-label">
          {item.parent}
        </label>

        <div className="branch">
          {item.child && item.child.length > 0 ? (
            item.child.map((ele, j) => (
              <div key={j} className="tree-node child">
                <input
                  type="checkbox"
                  id={`child-${i}-${j}`}
                  value={ele.category_name}
                  checked={ele.category_name === payloadData.childCategory}
                  onChange={(e) =>
                    setPayloadData({
                      ...payloadData,
                      childCategory: e.target.value,
                    })
                  }
                />
                <label htmlFor={`child-${i}-${j}`} className="tree-label">
                  {ele.category_name}
                </label>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
         <ToastContainer/>
      </div>
    ))}
</div>

      <button className="create-cat-btn" onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default CreateCategory;






















