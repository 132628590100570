import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import './Pagination.css'
import Div from '../Div'
import Sidebar from '../Sidebar'
import Spacing from '../Spacing'
import axios from 'axios'
import LZString from 'lz-string'
import { ShareBaseUrl } from '../config/Config'
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    XIcon,

} from "react-share";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import ShareOnSocial from "react-share-on-social";
import { Helmet } from 'react-helmet-async';
import { BaseUrl } from '../config/Config';
import logo from './main-logo-black.png';
import { IoMdShare } from "react-icons/io";
import { IoIosShareAlt } from "react-icons/io";
const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        // timeZoneName: 'short',
    });
};

export default function BlogDetailsPage() {
    const [blogDetail, setBlogDetail] = useState([])

    // const { id,title } = useParams();
    const [blogdata] = useSearchParams()
    // console.log("iddd", id,title)
    pageTitle('Blog Details');
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const detailBlog = async (blog_id) => {
        const data = await axios.get(`${BaseUrl}/getAdminBlogById?id=${blog_id}`)

        const result = data.data.message[0]
        // console.log("result", result)
        setBlogDetail(result)
    }
    useEffect(() => {
        if (blogdata.get('id') && blogdata.get('title')) {
            detailBlog(blogdata.get('id'))
        }
    }, [])


    // useEffect(() => {
    //     if (id) {
    //         detailBlog();
    //     }
    // }, [id]);

    // console.log("blogdetail===>", blogDetail)
    const createMarkup = (html) => {
        return { __html: html };
    };


    const id = encodeURIComponent(blogdata.get('id'));
    const title = encodeURIComponent(blogdata.get('title'))

    const redirect=`${ShareBaseUrl}/blogdetails?id=${id}&title=${title}`;
    const shareUrl = `${BaseUrl}/getShareData?id=${blogdata.get('id')}&redirect=${btoa(redirect)}`;
    // console.log("particular blog-detail", blogDetail)

    // const shareUrl = `http://test.logiglo.com/blogs/${id}`;
    // const shareUrl = `${logigloUrlBase}/Blog/${id}`;
    // const shareUrl = `https://972mtjzw-3000.inc1.devtunnels.ms/blogdetails?id=${blogdata.get('id')}&title=${blogdata.get('title')}`;
    // const shareUrl = `https://3j6lq1k0-7007.inc1.devtunnels.ms/api/getShareData?id=${blogdata.get('id')}`;
    // const shareUrl = `${BaseUrl}/blogdetails?id=${blogdata.get('id')}&title=${blogdata.get('title')}}`;
    return (
        <>
            <Helmet>
                <title>{blogDetail.blog_title}</title>
                <meta name="title" property="og:title" content={blogDetail.blog_title} />
                <meta name="description" property="og:description" content={blogDetail.blog_description} />
                <meta name="image" property="og:image" content={blogDetail.thumbnail} />
                <meta property="og:url" content={shareUrl} />
                <meta property="og:type" content="article" />
            </Helmet>

            {/* Start Blog Details */}

            <Spacing lg='60' md='40' />
            <Div className="container">
                <Div className="row">
                    <Div className="col-lg-8">

                        <div className="share-buttons">
                            <FacebookShareButton url={shareUrl} quote={blogDetail?.blog_title}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl} title={blogDetail?.blog_title}>
                          
                                < TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <LinkedinShareButton url={shareUrl} title={blogDetail?.blog_title}>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <WhatsappShareButton url={shareUrl} title={blogDetail?.blog_title}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                        </div>

                        {/* Start Details Post Content */}
                        <Div className="cs-post cs-style2">
                            <Div className="cs-post_thumb cs-radius_15">
                                <img src={LZString.decompressFromBase64(blogDetail.thumbnail)} className="w-100 cs-radius_15" alt={blogDetail.blog_title} />
                            </Div>
                            <Div className="cs-post_info">
                                {/* <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                                    <span className="cs-posted_by">{formatDate(blogDetail.created_at)}</span>
                                    <Link to="/blog" className="cs-post_avatar">{blogDetail.category}</Link>

                                </Div>
                                <ShareOnSocial
                                    textToShare="Check Out Syra Energy Latest Blogs"
                                    link={`https://syraenergy.com/blogdetails?id=${blogdata.get('id')}&title=${blogdata.get('title')}`}
                                    linkTitle={blogDetail.blog_title}
                                    linkMetaDesc={blogDetail.blog_description}
                                    linkFavicon={logo}
                                    noReferer
                                >
                                    <button className='share-blog-button'><IoIosShareAlt /></button>
                                </ShareOnSocial> */}
                                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                                    <span className="cs-posted_by">{formatDate(blogDetail.created_at)}</span>
                                    <Link to="/blog" className="cs-post_avatar">{blogDetail.category}</Link>

                                    {/* Add Share Button Here */}
                                    {/* <ShareOnSocial
                                        textToShare="Check Out Syra Energy Latest Blogs"
                                        link={`https://syraenergy.com/blogdetails?id=${blogdata.get('id')}&title=${blogdata.get('title')}`}
                                        linkTitle={blogDetail.blog_title}
                                        linkMetaDesc={blogDetail.blog_description}
                                        linkFavicon={logo}
                                        noReferer
                                    >
                                        <button className="share-blog-button"><IoMdShare /></button>
                                    </ShareOnSocial> */}
                                </Div>
                                <h2 className="cs-post_title">{blogDetail.blog_title}</h2>
                                {/* <p>{blogDetail.blog_description}</p> */}
                                <p
                                    className="blog-description"
                                    dangerouslySetInnerHTML={createMarkup(
                                        blogDetail.blog_description)}></p>


                            </Div>
                        </Div>
                        {/* End Details Post Content */}

                        {/* Start Comment Section */}
                        {/* <Spacing lg='30' md='30' />
                        <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
                        <Spacing lg='5' md='5' />
                        <p className="cs-m0">Your email address will not be published. Required fields are marked *</p>
                        <Spacing lg='40' md='30' />
                        <form className="row">
                            <Div className="col-lg-6">
                                <label>Full Name*</label>
                                <input type="text" className="cs-form_field" />
                                <Div className="cs-height_20 cs-height_lg_20" />
                                <Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>
                            <Div className="col-lg-6">
                                <label>Email*</label>
                                <input type="text" className="cs-form_field" />
                                <Div className="cs-height_20 cs-height_lg_20" />
                            </Div>
                            <Div className="col-lg-12">
                                <label>Website*</label>
                                <input type="text" className="cs-form_field" />
                                <Div className="cs-height_20 cs-height_lg_20" />
                            </Div>
                            <Div className="col-lg-12">
                                <label>Write Your Comment*</label>
                                <textarea cols={30} rows={7} className="cs-form_field" />
                                <Div className="cs-height_25 cs-height_lg_25" />
                            </Div>
                            <Div className="col-lg-12">
                                <button className="cs-btn cs-style1">
                                    <span>Send Message</span>
                                    <Icon icon="bi:arrow-right" />
                                </button>
                            </Div>
                        </form> */}
                        {/* End Comment Section */}
                    </Div>
                    <Div className="col-xl-3 col-lg-4 offset-xl-1">
                        {/* Start Sidebar */}
                        {/* <Spacing lg='0' md='80'/>
            <Sidebar /> */}
                        {/* End Sidebar */}
                    </Div>
                </Div>
            </Div>
            <Spacing lg='150' md='80' />
            {/* Start Blog Details */}


        </>
    )
}