import React, { useState } from 'react'
import Div from '../Div'
import Axios from 'axios'
import { BaseUrl } from '../config/Config'

export default function Newsletter({title, subtitle, placeholder}) {
  const [subscription,setsubscription] = useState("")
  const [alert,setalert] = useState("")
  const [response,setresponse] = useState()

  const handlesubmit = (e) =>{
    e.preventDefault()
    if(subscription===""){
      setresponse("")
       setalert("Fill this field")
       setTimeout(()=>{
        setalert("")
       },2000)
    }else{
      setalert("")
      const subscribe_data = {
        "email":subscription
      }
      Axios({
        method:'POST',
        url:`${BaseUrl}/subscribe`,
        data:subscribe_data,
        headers:{
          "Content-Type":"application/json"
        }
      }).then((res)=>{
        setresponse(res.data.message)
        console.log(res.data)
        setTimeout(()=>{
          setresponse("")
        },2000)
      }).catch((err)=>{
        setalert("Failed to send email")
        console.log(err)
      })
      setsubscription("")
    }

  }
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form action="#" className="cs-newsletter_form">
          <input type="email" value={subscription} onChange={(e)=>setsubscription(e.target.value)} className="cs-newsletter_input" placeholder={placeholder} />
          <button onClick={handlesubmit} className="cs-newsletter_btn"><span>Send</span></button>
        </form>
        <p style={{color:'red'}}>
          {alert}
        </p>
        <p style={{color:'green'}}>
          {response}
        </p>
        {/* <p style={{color:'red'}}>{alert}</p> */}
        <Div className="cs-newsletter_text" style={{color:'white'}}>{subtitle}</Div>
      </Div>
    </>
  )
}
