import { Route, Routes } from 'react-router-dom';
import Privacy from './components/PrivacyPolicy';
import Home from './components/Pages/Home';
import Layout from './components/Layout';
// import Accordion from './Accordion/Accordion';
import TermsOfUse from './components/TermsOfUse/Terms';
import Arc from './components/Arc/Arc';
import Careers from './Careers/Careers';
import LogIn from './components/LogIn/LogIn';
import CreateBlog from './components/CreateBlog/CreateBlog';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import BlogPage from './components/Pages/BlogPage';
import CreateCategory from './CreateCategory';
import BlogDashboard from './components/BlogDashboard/BlogDashboard';
import ProtectedRoute from './components/ProtectedRoute';



function App() {
  return (
    <>
      <Routes>
       
        <Route path="/createblog" element={<ProtectedRoute><CreateBlog/></ProtectedRoute>}></Route>
        <Route path='/editblog/:id' element={<ProtectedRoute><CreateBlog/></ProtectedRoute>}></Route>
        <Route path='/createCategory' element={<ProtectedRoute><CreateCategory/></ProtectedRoute>}></Route>
        <Route path='/blogDashboard' element={<ProtectedRoute><BlogDashboard/></ProtectedRoute>}></Route>
        <Route path="/login" element={<LogIn></LogIn>}></Route>
        <Route path="/" element={<Layout></Layout>}>

          <Route index element={<Home />} />
          <Route path='/internship' element={<Careers></Careers>} />
          <Route path='home' element={<Home></Home>}></Route>
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="Arc" element={<Arc />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="/blogdetails" element={<BlogDetailsPage />} />
          {/* <Route path="faq"element={<Accordion/>}/> */}
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
        </Route>
      </Routes>
    </>
  );
}

export default App;
