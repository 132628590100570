import React, { useEffect } from 'react';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import { pageTitle } from '../../helper';

import Accordion from '../../Accordion/Accordion';
import Arc from '../Arc/Arc';

const funfaceData = [
  {
    title: 'km Desired Range',
    factNumber: '200',
  },
  {
    title: 'Sec 0 To 40 kmph',
    factNumber: '03',
  },
  {
    title: 'kmph Top Speed',
    factNumber: '100',
  },
  {
    title: 'kWh Battery',
    factNumber: '4.0',
  },

  {
    title: 'kWh Battery',
    factNumber: '4.0',
  },
  {
    title: 'kWh Battery',
    factNumber: '4.0',
  },

];
const portfolioData = [
  {
    title: '3.9 kwh Energy Box',
    subtitle: 'Groundbreaking Energy Box to Reach Maximum Range',
    
    src: '/images/new_box.png',
  },
  {
    title: '8.5 kW Peak Power',
    subtitle: 'Crafted Powerful Electric Precision Mid Mount Motor',
    src: '/images/new_motor1.png',
  },
  {
    title: 'SCU',
    subtitle: 'Elevated SYRA Control Unit for Profound Performance',
    src: '/images/new_scu.png',
  },
  {
    title: 'Fastort',
    subtitle: 'Ingenious Tech for Accelerated Charger',
    src: '/images/new_charger5.png',
  },
  
];

export default function Home() {
  pageTitle('Home');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title=" <br/> Accelerating to <br/> Green"
        subtitle=" Powerful. Economical. Reliable."
        subtitle2="Redefining Mobility With Sustainable Innovation and Stylish Design "
    
        // subtitle="Efficient, EcoFriendly Electric Vehicles Redefining Mobility With Sustainable Innovation and Stylish Design"
        btnText="Explore"
        btnLink="/#Arc"
        scrollDownId="#service"
        socialLinksHeading=""
        // heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/teaser_front_transparent_crop.png"
      />
      {/* End Hero Section */}
      <Arc/>
      {/* Start Portfolio Section */}
      <Spacing lg="90" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Technology"
            subtitle=""
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider data={portfolioData} />
      </Div>
      {/* End Portfolio Section */}
      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Turn the tide on environmental degradation." />
      {/* End MovingText Section */}
      <Spacing lg="105" md="70" />
      {/* <Accordion/> */}
      

  
    </>
  );
}
