import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LogIn.css";
import { BaseUrl } from "../config/Config";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); 
 

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BaseUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      // console.log("Login Response:", data);

      const fullname = data.message[0].user_fullname
     
      if (response.ok && data.status === true && data.message.length > 0) {
        localStorage.setItem("user", JSON.stringify({email,password,fullname}));
        navigate("/blogdashboard"); 
      } else {
        throw new Error("Invalid email or password");
      }
    } catch (error) {
      setError("Invalid email or password");

      console.error("Login error:", error);
    }
  };

  return (
    <div className="syra-body-image">
      <div className="syra-login-container">
        <div className="syra-login-box">
          <h4>Login</h4>
          
          <form onSubmit={handleLogin}>
            <div className="syra-input-group">
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <span className="syra-icon">✉️</span>
            </div>
            <div className="syra-input-group">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="syra-icon">🔒</span>
            </div>
            {error && <p className="syra-error">{error}</p>}
            <button className="syra-login-bttn" type="submit">
              Log in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
